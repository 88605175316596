import 'bootstrap/js/dist/collapse.js';
import ScrollTrigger from '@terwanerik/scrolltrigger';

function init () {
	document.querySelectorAll('.main-nav__toggle').forEach(function(elm){
		elm.addEventListener('click', function(e){
			if(window.outerWidth <= 768) {
				e.preventDefault();
			}

			document.getElementById('site_header').classList.toggle('js-active');
		});
	});

	window.addEventListener('resize', function () {
		if(window.outerWidth > 768) {
			document.getElementById('site_header').classList.remove('js-active');
		}
	});

	$('.collapse').collapse();

	// Allows alternating flex-direction on block quotes
	$('.block-quote:eq(1), .block-quote:eq(3), .block-quote:eq(5), .block-quote:eq(7)').addClass('even');

	// Add smooth scroll to anchor links
	$(document).on('click', '.content-blocks a[href^="#"]', function (event) {
		event.preventDefault();

		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top - 20
		}, 500);
	});

	// Add smooth scroll navigation to homepage
	const $scrollLinks = $('.scroll-navigation .anchor-link');
	const activeScrollClass = 'anchor-link--active';

	$(document).on('click', '.scroll-navigation .anchor-link', function (event) {
		event.preventDefault();

		$scrollLinks.removeClass(activeScrollClass);
		$(this).addClass(activeScrollClass);
		$('html, body').animate({
			scrollTop: $($.attr(this, 'href')).offset().top
		}, 500);
	});

	$(document).on('click', '.scroll-navigation .anchor-nav--prev', function (event) {
		event.preventDefault();

		const $nextLink = $('.scroll-navigation .' + activeScrollClass).prev('.anchor-link');

		if($nextLink.length) {
			$scrollLinks.removeClass(activeScrollClass);
			$nextLink.addClass(activeScrollClass);

			$('html, body').animate({
				scrollTop: $($nextLink.attr('href')).offset().top
			}, 500);
		}

	});

	$(document).on('click', '.scroll-navigation .anchor-nav--next', function (event) {
		event.preventDefault();

		const $nextLink = $('.scroll-navigation .' + activeScrollClass).next('.anchor-link');

		if($nextLink.length) {
			$scrollLinks.removeClass(activeScrollClass);
			$nextLink.addClass(activeScrollClass);

			$('html, body').animate({
				scrollTop: $($nextLink.attr('href')).offset().top
			}, 500);
		}
	});

  // Scroll Triggers
  const trigger = new ScrollTrigger();
  trigger.add('[data-trigger]', {
    offset: {
      element: {
        x: 0,
        y: 0.5
      }
    },
    toggle: {
      callback: {
          in: (e) => {
            $scrollLinks.removeClass(activeScrollClass).blur();
            $('a[href="#' + e.element.id + '"]').addClass(activeScrollClass);
          },
          out: (e) => {
            $('a[href="#' + e.element.id + '"]').removeClass(activeScrollClass);
          }
      }
    }
  })
}

const ui = {
	init
};

export { ui as default };
