/**
 * Attribution - Based on D3 Block by ...
 * @author       Atanu Mallick
 * @author       Joe Tercero
 * @website      https://bl.ocks.org/atanumallick/8d18989cd538c72ae1ead1c3b18d7b54
 **/

import d3 from "./d3";
import * as topojson from "topojson";
import worldData from "../assets/geojson/world-landmass.json";
import ScrollTrigger from '@terwanerik/scrolltrigger';

const dpath = "M10.5478 24.7479L11.4317 25.6317L12.3155 24.7479C13.2805 23.7829 14.3873 22.6106 15.4492 21.4842L15.544 21.3836C16.5795 20.2853 17.5648 19.2402 18.3589 18.4461C22.1847 14.6203 22.1847 8.41741 18.3589 4.5916C14.5331 0.765791 8.33021 0.765791 4.5044 4.5916C0.678596 8.41741 0.678596 14.6203 4.5044 18.4461C5.29815 19.2398 6.28281 20.2843 7.31773 21.3821L7.41399 21.4842C8.4759 22.6106 9.58266 23.7829 10.5478 24.7479Z";

const width = 960;
const height = 960;
const scale = 480;
const clipAngle = 180;
const config = {
  yawSpeed: 0.002,
  yaw: 0,
  rollSpeed: 0.0005,
  roll: -15,
  pitch: 10
};
let autorotate = {};
let locations = [];
let circleLocations = [];
let markerLocations = [];
const svg = d3
  .select("#globe-svg")
  .attr("width", width)
  .attr("height", height)
  .attr("viewBox", "0 0 960 960");

svg.append("defs").append("path")
    .datum({type: "Sphere"})
    .attr("id", "sphere")
    .attr("d", path);

svg.append("use")
  .attr("class", "d3-globe--fill")
  .attr("xlink:href", "#sphere");

const markerGroup = svg.append("g")
  .attr("class", "d3-globe--markers");
const outlineGroup = svg.append("g")
  .attr("class", "d3-globe--outline");
const projection = d3.geoOrthographic();
const initialScale = projection.scale(scale).translate([(width / 2), (height / 2)]);
const path = d3.geoPath().projection(projection);
const center = [width / 2, height / 2];
let lastTime = d3.now();


function shuffleLocations(arr) {
  for(let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr;
}

/**
 * Draw the Globe Layer with an External JSON
 */
function drawExternalLocations() {
  const api_url = typeof GLOBE_API_URL === 'undefined' ? process.env.API_URL : GLOBE_API_URL;
  const locations_query =
    "query=query { viewer {siteMeta {openLocations" +
    " {title slug locationUrl geoLocation {lat,lng}}}}}";
  const locations_end_point = api_url + "?" + locations_query;
  d3.json(locations_end_point)
    .then(() => d3.json(locations_end_point))
    .then(locationData => {
      let {
        data: {
          viewer: {
            siteMeta: { openLocations }
          }
        } = { viewer: { siteMeta: { openLocations: {} } } }
      } = locationData;
      locations = shuffleLocations(openLocations.filter(location => !!location.geoLocation)).map(location => {
          const {
            title,
            locationUrl,
            geoLocation: { lat: latitude, lng: longitude }
          } = location;
          return { latitude, longitude, title, locationUrl };
        });
      markerLocations = locations.slice(0,20);
      circleLocations = locations;

      svg.selectAll("path").attr("d", path);
      drawCircles();
      drawMarkers();
      drawText();
    })
    .catch(() => {});
}

/**
 * Draw the Globe Layer
 */
function drawGlobe() {
  svg
    .append("path")
    .datum({
      type: "FeatureCollection",
      features: [topojson.feature(worldData, worldData.objects.land)]
    })
    .attr("d", path)
    .attr("class", "d3-globe--land")
    .style("fill", (d, i) => "#0960E1")
    .style("fill-opacity", "1")
    .style("opacity", "1");
}

/**
 * Draw the Graticule Layer
 */
function drawGraticule() {
  const graticule = d3.geoGraticule();

  svg
    .append("path")
    .datum(graticule)
    .attr("class", "graticule")
    .attr("d", path)
    .style("fill", "#fff")
    .style("fill-opacity", "0")
    .style("stroke", "#ccc");
}

/**
 * Configure Globe Rotation
 */
function setInitialAngles() {
  const rotation = [
    config.yaw,
    config.roll,
    config.pitch
  ]
  projection.rotate(rotation)
}


/**
 * Update Globe Rotation
 */
function rotate(elapsed) {
  const rotation = projection.rotate();
  const now = d3.now();
  const diff = now - lastTime;
  if (diff < elapsed) {
    rotation[0] += diff * config.yawSpeed;
    // rotation[1] += diff * config.rollSpeed;
    projection.rotate(rotation);
  }
  lastTime = now;
  svg.selectAll("path").attr("d", path);
  drawCircles();
  drawMarkers();
  drawText();
}

/**
 * Enable the the Globe Rotation
 */
function stopRotation() {
  autorotate.stop();
}

/**
 * Enable the the Globe Rotation
 */
function startRotation() {
  autorotate.restart(rotate);
}

/**
 * Draw the Location Markers Layer
 */
function drawCircles() {
  const markers = markerGroup.selectAll("circle").data(circleLocations);
  markers
    .enter()
    .append("circle")
    .merge(markers)
    .attr("cx", d => projection([d.latitude, d.longitude])[0])
    .attr("cy", d => projection([d.latitude, d.longitude])[1])
    .attr("class", d => {
      const coordinate = [d.latitude, d.longitude];
      const gdistance = d3.geoDistance(coordinate, projection.invert(center));
      return gdistance > 1.57 ? "d3-globe--dots hide" : "d3-globe--dots show";
    })
    .attr("r", 4);

  markerGroup.each(function() {
    this.parentNode.appendChild(this);
  });
}

function drawMarkers() {
  const markers = markerGroup.selectAll("path").data(markerLocations);
  markers
    .enter()
    .append('svg:a')
    .attr('xlink:href', d => d.locationUrl)
    .attr('target', '_blank')
    .append("path")
    .merge(markers)
    .attr("d", dpath)
    .attr("transform", d => { return "translate(" + (projection([d.latitude, d.longitude])[0] - 8.5 )+ "," + (projection([d.latitude, d.longitude])[1] - 21) + ") scale(0.75)"; })
    .attr("class", d => {
      const coordinate = [d.latitude, d.longitude];
      const gdistance = d3.geoDistance(coordinate, projection.invert(center));
      return gdistance > 0.6 ? "d3-globe--pins hide" : "d3-globe--pins show";
    })
    .on('mouseover', function() {
      stopRotation();
    })
    .on('mouseout', function() {
      startRotation()
    });

  markerGroup.each(function() {
    this.parentNode.appendChild(this);
  });
}

function drawText() {
  const markers = markerGroup.selectAll("text").data(markerLocations);
  markers
    .enter()
    .append("svg:text")
    .merge(markers)
    .attr("transform", d => { return "translate(" + (projection([d.latitude, d.longitude])[0] - 8 )+ "," + (projection([d.latitude, d.longitude])[1] - 20) + ")"; })
    .attr("dx", "-0.35em")
    .attr("dy", "0.95em")
    .text(d => d.title)
    .attr("class", d => {
      const coordinate = [d.latitude, d.longitude];
      const gdistance = d3.geoDistance(coordinate, projection.invert(center));
      return gdistance > 0.6 ? "d3-globe--label hide" : "d3-globe--label show";
    });

  markerGroup.each(function() {
    this.parentNode.appendChild(this);
  });
}

/**
 * Resize the Globe Responsively
 */

function sizeChange() {
  d3.select('.d3-globe--animate-scale').style("transform", "scale(" + $("#globe-container").height()/960 + ")");
}

/**
 * Animates the globe into the next section
 */
function animateGlobe() {
  svg.attr("transform", "scale(0)");
}


/**
 * Runs the animateGlobe animation in reverse
 */
function reverseAnimateGlobe() {
  svg.attr("transform", "scale(" + $("#globe-container").height()/960 + ")");
}

/**
 * Adds a scroll triggered animation to d3 object
 */
function createAnimation() {
  // Globe Animation Triggers
  const trigger = new ScrollTrigger();
  let isAnimating = false;

  trigger.add('[data-trigger-globe]', {
    offset: {
      element: {
        x: 0,
        y: 0.8
      }
    },
    toggle: {
      callback: {
          in: (e) => {
            // reverseAnimateGlobe();
          },
          out: (e) => {
            // animateGlobe();
          }
      }
    }
  })
}

/**
 * Initiate the Draw
 */
function init(showmarkers, showgraticule, startrotate) {
  d3.select(window).on("resize", sizeChange);

  drawGlobe();

  if (showmarkers) {
    drawExternalLocations();
  }

  if (showgraticule) {
    drawGraticule();
  }

  if (startrotate) {
    setInitialAngles();
    autorotate = d3.timer(rotate);
  }

  createAnimation();
}

const d3Globe = {
  init,
  sizeChange
};

export { d3Globe as default };
