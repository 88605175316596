function init() {
    // Show an element
    let show = function (elem) {

        // Get the natural height of the element
        let getHeight = function () {
            elem.style.display = 'block'; // Make it visible
            let height = elem.scrollHeight + 'px'; // Get it's height
            elem.style.display = ''; //  Hide it again
            return height;
        };

        let height = getHeight(); // Get the natural height
        elem.classList.add('is-visible'); // Make the element visible
        elem.style.height = height; // Update the max-height

        // Once the transition is complete, remove the inline max-height so the content can scale responsively
        window.setTimeout(function () {
            elem.style.height = '';
        }, 350);

    };

    // Hide an element
    let hide = function (elem) {

        // Give the element a height to change from
        elem.style.height = elem.scrollHeight + 'px';

        // Set the height back to 0
        window.setTimeout(function () {
            elem.style.height = '0';
        }, 1);

        // When the transition is complete, hide it
        window.setTimeout(function () {
            elem.classList.remove('is-visible');
        }, 350);

    };

    // Toggle element visibility
    let toggle = function (elem, timing) {

        // If the element is visible, hide it
        if (elem.classList.contains('is-visible')) {
            hide(elem);
            return;
        }

        // Otherwise, show it
        show(elem);

    };

    // Add onclick to toggle class

    let toggleContent = document.querySelector('.toggleContent');
    if (toggleContent) {
        toggleContent.onclick = function (event) {

            // Prevent default link behavior
            event.preventDefault();

            toggleContent.classList.toggle('active');


            // Get the content
            let content = document.querySelector(event.target.hash);
            if (!content) return;

            // Toggle the content
            toggle(content);

        };
    }
}

const banner = {
  init
};

export { banner as default };
