import Slideshow from './js/slideshow';
import UI from './js/ui';
import Globe from './js/d3-globe';
import Banner from './js/banner';

Slideshow.init("glide");
UI.init();
Globe.init(true, false, true);
Banner.init();

$(function() {
  Globe.sizeChange();
});
